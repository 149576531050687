.statistic-box{
    width: 80%;
    height: 150px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px -3px rgba(179, 179, 179, 0.76); 
    box-shadow: 0px 0px 10px -3px rgba(179, 179, 179, 0.76);
    color: white;
    font-size: 20px;
    padding: 10px;
    margin: 15px 15px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .statistic-box{
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .statistic-box{
        width: 50%;
    }
}
.stat-number{
    font-size: 30px;
}
.stat-under-number{
    font-size: 12px;
}
.box-1{
    background-color: #F43558;
}

.box-2{
    background-color: #53D769;
}

.box-3{
    background-color: #157EFB;
}

.box-4{
    background-color: #2B2728;
}