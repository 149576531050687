

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

form .btn{
  height: 50px !important; 
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table th, table td {
  padding: 14px 0 !important;
}

table.details-table th, table.details-table td{
  padding: 20px;
  font-size: 20px;
}

table.details-table th{
  width: 230px;
}

table.details-table td{
  width: 400px;
}

table.details-table button{
  height: 40px;
  width: 90% !important;
  margin: 0 auto !important;
}

.container {
  max-width: 1200px !important;
}
.btn-danger{
  width: 170px;
  height: 40px !important;
}

span.navbar-toggler-icon{
  color: black !important;
}

img.table-pack-image{
  height: 50px !important;
  width: 100px !important;
  border-radius: 10px;
}