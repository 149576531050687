.table-pack-image{
    height: 50px;
}

.form-check-input {
    width: 20px; /* Set your desired width */
    height: 20px; /* Set your desired height */
}
.form-check-label{
    font-size: 20px;
    margin-left: 8px;
    cursor: pointer !important;
}