#carousel {
  position: relative;
  width: 100%;
  height: 700px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
}

#carousel div {
  position: absolute;
  transition: transform 1s, left 1s, opacity 1s, z-index 0s;
  opacity: 1;
}

#carousel div img {
  width: 100%; /* Adjusted to make the images responsive within the carousel */
  transition: width 1s;
}

#carousel div.hideLeft {
  left: 0%;
  opacity: 0;
  transform: translateY(50%) translateX(-50%);
}

#carousel div.hideRight {
  left: 100%;
  opacity: 0;
  transform: translateY(50%) translateX(-50%);
}

#carousel div.prev {
  z-index: 5;
  left: 30%;
  transform: translateY(50px) translateX(-50%);
}

#carousel div.prevLeftSecond {
  z-index: 4;
  left: 15%;
  transform: translateY(50%) translateX(-50%);
  opacity: .7;
}

#carousel div.selected {
  z-index: 10;
  left: 50%;
  transform: translateY(0px) translateX(-50%);
}

#carousel div.next {
  z-index: 5;
  left: 70%;
  transform: translateY(50px) translateX(-50%);
}

#carousel div.nextRightSecond {
  z-index: 4;
  left: 85%;
  transform: translateY(50%) translateX(-50%);
  opacity: .7;
}

.packCarousel {
  width: 550px;
  transition: width 1s;
  margin: 0 auto;
  height: 800px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76);
  background-color: #f0f0f0; /* Change background color to blue */
  overflow: hidden;
}


.bg-img {
  width: 100%;
}


.packCarousel .packs-para {
  margin-bottom: 5px;
}

.packCarousel h3,
.packCarousel h4 {
  margin-bottom: 10px;
}

.packCarousel .btn {
  margin-top: 10px;
}

.swiper-fixed-height-100 {
  height: 1100px;
}

.swiper-fixed-width-300 {
  width: 300px;
}