@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
}

.container2 {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container2:hover,
.container2:active {
  .top:before,
  .top:after,
  .bottom:before,
  .bottom:after {
    margin-left: 200px;
    transform-origin: -200px 50%;
    transition-delay: 0s;
  }
  .center {
    opacity: 1;
    transition-delay: 0.2s;
  }
}

.top:before, .bottom:before, .top:after, .bottom:after {
  content: '';
  display: block;
  position: absolute;
  width: 200vmax;
  height: 200vmax;
  top: 50%;
  left: 50%;
  margin-top: -100vmax;
  transform-origin: 0 50%;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
  z-index: 10;
  opacity: 0.65;
  transition-delay: 0.2s;
}

.top:before {
  transform: rotate(45deg);
  background: #e46569;
}

.top:after {
  transform: rotate(135deg);
  background: #ecaf81;
}

.bottom:before {
  transform: rotate(-45deg);
  background: #60b8d4;
}

.bottom:after {
  transform: rotate(-135deg);
  background: #3745b5;
}

.center {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
  transition-delay: 0s;
  color: #333;
}

.center input {
  width: 100%;
  padding: 15px;
  margin: 5px;
  border-radius: 1px;
  border: 1px solid #ccc;
  font-family: inherit;
}

button.login-btn{
    width: 100%;
    padding: 15px;
    margin: 5px;
    border-radius: 1px;
    border: 1px solid #ccc;
    font-family: inherit;
    background-color: #e46569;
    color: white;
    
}

button.login-btn:hover{
    background-color: #ba4044;
}



/*yassir style*/
.btn-orange {
  background-color: orange;
  color: white; /* Text color */
  border-color: orange; /* Border color */
}

.btn-orange:hover {
  background-color: darkorange; /* Change color on hover */
  border-color: darkorange; /* Change border color on hover */
}
