@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,600&family=Poppins&family=Rubik+Gemstones&family=Tapestry&family=Ubuntu:ital@1&display=swap');

body{
  font-family: 'Montserrat', sans-serif;
}
.custom-toggler {
    background-color: white; 
    border: none;
  }
  
  .custom-toggler:focus {
    outline: none; 
  }
  
  .nav-link{
    font-size: 18px !important;
    font-family: 'Montserrat', sans-serif;
    color: #fff !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); 
    border-radius: 5px;
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    .nav-link {
      margin-right: 0; 
    }
    img.logo{
      height: 25px !important;
    }
    .navbar-brand{

    }
  }

  .nav-link:hover{
    background-color: #D65907;
  }

  a.title{
    color:white !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6) !important;
  }
  .custom-icon {
    color: #fff !important;
  }

.logo{
  height: 30px !important;
}

