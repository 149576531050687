footer {
    padding: 7em 0;
    height: fit-content !important;
  }
  a.company{
    height: fit-content;
    width: fit-content;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px auto !important;
  }
  a.company:hover{
    background-color: #ee6306;
  }
  .col div.text span{
    cursor: pointer !important;
  }
  .footer-10 {
    background: #ee6306;
    padding: 0 0 20px 0; }
    .footer-10 .border-top, .footer-10 .border-bottom {
      border-color: rgba(255, 255, 255, 0.1) !important; }
    .footer-10 .footer-heading {
      font-size: 18px;
      color: #fff;
      margin-bottom: 30px; }
    .footer-10 p {
      color: rgba(255, 255, 255, 0.3); }
    .footer-10 a {
      color: #D65907; }
    .footer-10 .con.con-1 {
      background: #de5e08; }
    .footer-10 .con.con-2 {
      background: #D65907; }
    .footer-10 .con.con-3 {
      background: #de5e08; }
    .footer-10 .list-unstyled li a {
      color: rgba(255, 255, 255, 0.2); }
      .footer-10 .list-unstyled li a:hover {
        color: #fff; }
    .footer-10 .con-info .icon2 {
      width: 50px;
      height: 50px;
      background: #f5820764;
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 10px; }
      .footer-10 .con-info .icon2 span {
        color: #fff;
        font-size: 24px; }
    .footer-10 .con-info span {
      color: rgba(255, 255, 255, 0.8); }
    .footer-10 .subscribe-form .form-group {
      position: relative;
      margin-bottom: 0;
      border-radius: 0; }
      .footer-10 .subscribe-form .form-group input {
        background: white !important;
        border: none !important;
        outline: none !important;
        color: rgba(0, 0, 0, 0.3) !important;
        font-size: 16px;
        border-radius: 0; }
        .footer-10 .subscribe-form .form-group input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(0, 0, 0, 0.3) !important; }
        .footer-10 .subscribe-form .form-group input::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(0, 0, 0, 0.3) !important; }
        .footer-10 .subscribe-form .form-group input:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(0, 0, 0, 0.3) !important; }
        .footer-10 .subscribe-form .form-group input:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(0, 0, 0, 0.3) !important; }
        .footer-10 .subscribe-form .form-group input:focus {
          outline: none !important;
          -webkit-box-shadow: none;
          box-shadow: none; }
      .footer-10 .subscribe-form .form-group .submit {
        color: #fff !important;
        display: block;
        width: 130px;
        height: 52px;
        font-size: 16px;
        background: #ee6306 !important;
        border: none;
        border-radius: 0; }
        .footer-10 .subscribe-form .form-group .submit:hover, .footer-10 .subscribe-form .form-group .submit:focus {
          text-decoration: none !important;
          outline: none !important; }
    .footer-10 .subscribe-form .subheading {
      display: inline-block;
      margin-top: 5px;
      color: rgba(255, 255, 255, 0.3);
      font-size: 13px; }
    .footer-10 .ftco-footer-social li a {
      background: rgba(255, 255, 255, 0.1);
      color: #fff; }


      .ftco-footer-social li {
        list-style: none;
        margin: 0 10px 0 0;
        display: inline-block; }
        .ftco-footer-social li a {
          height: 40px;
          width: 40px;
          display: block;
          background: rgba(0, 0, 0, 0.05);
          border-radius: 50%;
          position: relative; }
          .ftco-footer-social li a span {
            position: absolute;
            font-size: 20px;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
          .ftco-footer-social li a:hover {
            color: #fff; }

        .ftco-section {
                padding: 12em 0; 
        }
        .ftco-section h2 {
                  margin-bottom: 0; 
        }
              
