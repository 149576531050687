
div.nav-cont{
    background-color: #803605;
}

div.small-nav{
    height: 65px;
    background-color: #EE6306;
    border-bottom: 2px solid white;
}

.nav-element{
    font-size: 16px;
    margin: auto 15px;
    color: white;
    cursor: pointer;
    transition: all 10ms;
    padding: 5px;
}

.nav-element:hover{
    border-bottom: 2px solid gold;
}

.heading-class-blue{
    color:black;
    font-weight: 600;
}
.heading-class-gold{
    color:#573D00;
    font-weight: 600;
}



.tarif-price{
    background-color: #573D00;
    color: aliceblue;
    padding: 22px 10px;
}

p.para-vol{
    font-size: 13px !important;
    color: black;
}

@media screen and (min-width:950px) and (max-width:1220px){
    p.para-vol{
        font-size: 11px !important;
    }
}


@media screen and (max-width:990px){
    .marginB{
        margin-bottom: 13px !important;
    }

}

@media screen and (max-width:767px){
    .marginB{
        margin-bottom: 13px !important;
    }
    .vol-element{
        width: 300px !important;
    }
    .direct-vol{
        margin-top: 9px !important;
    }
    div p.para-company{
        font-size: 15px !important;
    }
    div p.escale-text {
        font-size: 10px !important;

    }
    .marginD{
        margin-top: 28px !important;
        margin-bottom: 20px !important;
    }
    .marginO{
        margin-top: 10px !important;
    }
}

@media screen and (max-width:1090px){
    p.para-vol{
        font-size: 11px !important;
    }
}
p{
    margin: 0 !important;
}

.para-class-blue-bold{
    color:black;
    font-size: 17px;
    font-weight: 600;
}

.para-class-blue{
    color:black;
    font-size: 15px;
}

.heading-class-white{
    color: white;
    font-weight: 600;
}

.description-icon{
    height: 90px;
    padding: 10px;
    border-radius: 20px;
    filter: invert(17%) sepia(94%) saturate(1874%) hue-rotate(40deg) brightness(94%) contrast(101%);
}

.icon{
    height: 30px;
    filter: invert(10%) hue-rotate(180deg);
}

.dashed-line {
    width: 100%; 
    height: 2px;
    border-bottom: 2px dashed  #000; 
    margin: auto;
  }

  .btn-reserver{
    height: 45px !important;
    width: 250px !important;
  }
  .company {
    height: 30px;
  }

  .description, .hotel, .vols {
    padding: 50px 0px !important;
    
  }

  .tarifs{
    padding-bottom: 50px;
  }
  .vols{
    background-image: url('../images/pattern.png'); 
    background-repeat: repeat; 
    background-size: auto; 
    max-width: 100% !important;
  }
  .tarifs{
    background-image: url('../images/pattern.png'); 
    background-repeat: repeat; 
    background-size: auto; 
    max-width: 100% !important;
  }
  .description{
    background-image: url('../images/pattern.png'); 
    background-repeat: repeat; 
    background-size: auto; 
    opacity: 1;
    z-index: -1;
    max-width: 100% !important;
  }

  .tarifs{
  }
 


.description-element{
    background-color: white;
    padding: 20px;
    -webkit-box-shadow: 4px 10px 11px -2px rgba(130,130,130,0.82); 
    box-shadow: 4px 10px 11px -2px rgba(130,130,130,0.82);
    width: 80% !important;
}

@media (max-width: 992px) {

    .description-element{
        margin-top: 20px !important;
        width: 90% !important;
    }

    .details-hotel div{
        font-size: 15px !important;
    }

}

@media screen and (max-width: 992px) and (min-width:600px){
    img.hotel-image{
        width:500px !important;
    }
    .details-hotel{
        width: 500px !important;
    }
}

@media screen and (max-width: 600px){
    img.hotel-image{
        width:90% !important;
        height: auto !important;
    }
    .details-hotel div{
        font-size: 15px !important;
    }
}
.hotel{
    background-color: #EE6306;
    position: relative;
    padding: 20px;
}

.hotel::before {
    content: "";
    background-image: url('../images/pattern.png'); 
    background-repeat: repeat; 
    background-size: auto; 
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }  
  

.details-hotel div{
    color: white;
    font-size: 20px;
    margin: 10px 0;
}

.details-hotel{
    width: 90%;
    border-radius: 15px;
    background-color: #bc530c;
    padding: 20px 100px;
    margin-top: 20px;
    /* -webkit-box-shadow: 0px 0px 10px 0px #07141aab; 
    box-shadow: 0px 0px 10px 0px #07141aab; */
}

img.hotel-image{
    width: 90%;
    height: auto !important;
    border-radius: 10px;
    margin-top: 10px;
}

@media (min-width: 992px) {
    .hotel-makka{
        border-left:5px solid #bc530c;
    }
    .hotel-medina{
        border-right:5px solid #bc530c;
        
    }
    
    
}

@media (max-width: 992px) {
    .hotel-makka{
        border-top: 10px solid #bc530c;
        margin-top: 10px;
        padding-top: 10px !important;

    }
    .hotel-medina{
    }
}

.vol-element{
    width: 90%;
    margin: auto !important;
    height: fit-content;
    padding: 10px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 10px 0px #727272de; 
    box-shadow: 0px 0px 10px 0px #727272de;
    background-color: white !important;
}

.tarif-element{
    margin: auto !important;
    width: 80%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px #727272de; 
    box-shadow: 0px 0px 10px 0px #727272de;
    padding: 30px 0px;
    background-color: white !important;
}

@media screen and (max-width:1300px) and (min-width:990px) {
    .tarif-element{
        width: 97% !important;
        
    }
}
.tarif-icon{
    height: 60px;
    padding: 10px;
    filter: invert(17%) sepia(94%) saturate(1874%) hue-rotate(40deg) brightness(94%) contrast(101%);
}