@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300&family=Montserrat:ital,wght@0,500;1,600&family=Poppins&family=Prompt:wght@800&family=Questrial&family=Rubik+Gemstones&family=Tapestry&family=Ubuntu:ital@1&display=swap');

body{
    overflow-x: hidden !important;
    box-sizing: content-box !important;

}
.background-images {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 95vh;
    z-index: 0;
    position: relative;
    overflow: hidden; 

  } 
  


  
  .background-images::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/travel.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
     animation: slider 40s linear infinite; 
    z-index: -1; 
    
  }
  
   @keyframes slider {
    0%, 11.11%, 44.44% {
        background-image: url("../images/main.jpg");
    }

    44.44%, 55.55%, 66.66% {
        opacity: 0.75 !important;
    }
    22.22%, 55.55% {
        background-image: url("../images/medina.jpg"); 
    }
    33.33%, 66.66% {
        background-image: url("../images/travel.jpg"); 
    }
    33.33%, 66.66% {
        background-image: url("../images/2148130115.jpg"); 
    }
    
    77.77% {
        opacity: 9;
    }


} 
  

.react-dates {
    background-color: #fff; /* Set the background color */
    border: 1px solid #ccc; /* Add a border */
    border-radius: 100px !important; /* Add border-radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
  }

h1.text1{
    font-family: 'Prompt', sans-serif;
    font-size: 50px !important;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); 
}
h2.text2{
    font-family: 'Questrial', sans-serif;
    font-size: 40px !important;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); 
}


.title-blue {
   font-weight: 600;
   color: #0C3C54;
}

.text3{
    font-family: 'Questrial', sans-serif;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.905); 
}

.simple-reservation{
    padding: 20px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.905);
    top:50%;
    position: relative;
}

.mobile-reservation{
    background-color: none;
    height: 320px;
    position: relative;
    top:5%;
    width: 280px;
    border-radius: 15px;
    padding: 15px 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.76); 
    box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.76);
}



.mobile-reservation img.icon-image{
    height: 15px;
    width:15px;
    scale:1.5;
}

.mobile-reservation label{
    font-size: 15px !important;
    color: #FF6C00;
    margin: 0 !important;
    text-shadow: 1px 1px 2px rgba(176, 176, 176, 0.585); 
    width:100%;
    background-color: #fbfbfb;
    
    border-bottom: 2px solid ;
}
.mobile-reservation .input-group{
    background-color: #f6f5f4;
    width:100%;
    padding: 5px 10px;
    margin: 0 !important;
}
.mobile-reservation .btn{
    height: 40px !important;
    background-color: #ffa600 !important;
    color: white;
    border: 1px solid #ffa600;
    text-shadow: 1px 1px 2px rgba(57, 57, 57, 0.585); 
    -webkit-box-shadow: 0px 0px 3px 0px #964a14b3; 
    box-shadow: 0px 0px 3px 0px #964a14b3;
}
.mobile-reservation .row{

}

.container-3{
    position: relative;
}






@media screen and (min-width: 390px) and (max-width: 767px) {
    h2.text2 {
        font-size: 25px !important;
    }
    h1.text2 {
        font-size: 35px !important;
    }
    .element{
        height: 220px !important;
        margin: 0 !important;
    }
    .element-first{
        margin-top: 60px !important;
    }   
    .pack{
        margin-bottom: 20px;
    }
    h5.element-header{
        font-size: 17px;
        font-weight: 600;
    }
    .element p {
        font-size: 13.5px;
    }
    p.para-element{
        margin-top: 0px !important;
    }
}

@media screen and (max-width: 390px) {
    h5.element-header{
        font-size: 13px !important;
        font-weight: 600;
        margin-top: 12px !important;
    }
    p.para-element{
        font-size: 12px !important;
        margin-top: 5px !important;

    }
}
@media (max-width: 550px) {
    .packs-title{
        font-size: 15px !important;
        padding: 10px !important;
    }
    .packs-subtitle{
        font-size: 12px !important;
    }

    h1.text1{
        font-size: 30px !important;  
         
    }
    h2.text2{
        font-size: 19px !important;
    }
}

.my-select{
    width: 250px !important;
}
@media (max-width: 650px) {
    .bi-x-lg{
        width: 15px !important;
        height: 15px !important;
    }
    .modal-row h2 {
        font-size: 15px !important;
        font-weight: 600;
    }
    .ReactModal__Content {
      }
    .modal-row h5{
        font-size: 15px !important;
        margin-bottom: 10px !important;
        margin-top: 20px !important;
    }
}

@media (min-width: 992px){



    .simple-reservation{
        display: block !important;
    }
    .mobile-reservation{
        display: none !important;
    }
}
@media (max-width: 765px) {
    .pack{
        width: 320px !important;
    }
    .map{
        width: 95% !important;
    }
}


.img-gallery-container{
    width: 95%;
    margin: auto !important;
}
@media screen and (min-width:765px) and (max-width: 992px) {
    .pack{
        width: 85% !important;
    }
}

@media (max-width: 992px) {

    

    .simple-reservation{
        display: none !important;
    }
    .mobile-reservation{
        display: block !important;
    }

    .background-images{
        min-height: 600px !important;
        height: 600px !important
    }
    .simple-reservation .btn-orange {
        margin-top: 10px !important;
    }
}
@media (max-width: 768px) {
    .sponsor-img{
        width: 100px !important;
    }
    .icon-container{
        margin: 5px;
    }

    .carousel-control-prev {
        background-color: rgba(0, 0, 0, 0.344); /* Set the background color to black */
        width: 30px; /* Adjust the width as needed */
      }
      
      /* Style the right carousel control button */
      .carousel-control-next {
        background-color: rgba(0, 0, 0, 0.344); /* Set the background color to black */
        width: 30px; /* Adjust the width as needed */
      }

      .map{
        margin-top: 30px;
        width:90%;
height: 400px;
      }
}
.element{
    width: 90%;
    height: 90%;
    border-radius: 5px;
    margin: 20px 0;
    background-color: white;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76); 
    box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76);
    z-index: 0;
    padding: 0 10px;
    transform: scale(1);
    transition: transform 0.3s ease;
}

.customDatePicker{
    z-index: 100000 !important;
}

form.reservation-form{
    margin: 0 !important;
}

.element:hover{
    transform: scale(1.1); 
}

h5.element-header{
    font-size: 17px;
    font-weight: 600;
}
.element p {
    font-size: 13.5px;
}

.css-1pahdxg-control {
    z-index: 1000 !important;
  }
.packs-title{
    background-color: #ee6306;
    padding: 20px 0;
    color: white;
}
.para-element{
    margin-top: 28px !important;
}



.datePicker { width: 500px !important; cursor: pointer !important; z-index: 10000 !important; }
.react-datepicker__input-container input { width: 300px !important; cursor: pointer !important; z-index: 10000 !important;}


.DateRangePicker_picker 
.DateRangePicker_picker_1 
.DateRangePicker_picker__directionLeft 
.DateRangePicker_picker__directionLeft_2{
    z-index: 100000 !important;
}
.element h5{
    color: darkblue;
}
.packs-subtitle
{
    color: rgba(244, 244, 244, 0.911);
}

.contact-us{
    max-width: 500px !important;
    border-radius: 15px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76); 
    box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76);
}
.map{
    border-radius: 15px;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76); 
    width:600px;
}
.contact-row{
}


.carousel-item .row .col {
    padding: 10px;
}
.sponsor-img{
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(25, 25, 25, 0.76); 
    box-shadow: 0px 0px 10px 0px rgba(25, 25, 25, 0.76);
}
.icon-image{
    height: 20px;
    width:20px;
}
.icon-container{
    width: fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 5px ;
}
.home-icon{
    color: grey !important;
    padding: 10px !important;
}

h5.test{
    float: inline-end !important;
}
/*.pack{
    width: 90%;
    height: 550px !important;
    padding: 10px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76); 
    box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.select-search{
    height: 100% !important;
}
.pack p.packs-para{
    font-size: 12px !important;
    margin: 2px 0 !important;
}
.pack h5{
    font-size: 15px !important;

}
.pack img.bg-img{
    height: 150px !important;
    width: 100% !important;
    border-radius: 15px;

}
.pack img.icon{
    width: 18px;
    height: 18px;
}*/




.pack {
    width: 100%;
    height: 600px !important;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.76);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .pack p.packs-para {
    font-size: 12px !important;
    margin: 2px 0 !important;
  }
  
  .pack h5 {
    font-size: 15px !important;
  }
  
  .pack img.bg-img {
    height: 150px !important;
    width: 100% !important;
    border-radius: 15px;
  }
  
  .pack img.icon {
    width: 18px;
    height: 18px;
  }
  
  

.carousel-control-prev {
    background-color: rgba(0, 0, 0, 0.344); /* Set the background color to black */
    width: 80px; /* Adjust the width as needed */
    height: 100%; /* Adjust the height as needed */
  }
  
  /* Style the right carousel control button */
  .carousel-control-next {
    background-color: rgba(0, 0, 0, 0.344); /* Set the background color to black */
    width: 80px; /* Adjust the width as needed */
    height: 100%; /* Adjust the height as needed */
  }
  
  /* Style the arrow icon color (optional) */
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    color: white; /* Set the arrow color to white or any color you prefer */
  }


.icon{
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

p.packs-para{
    margin: 0 !important;
    
}

.helper{
    font-size: 16px !important;
}

.btn-orange{
    background-color: #EE6306 !important;
    color: white;
}
.btn-orange:hover{
    background-color: #f7910b !important;
    color:white;
}

.element-icon{
    width: 90px;
    height:90px;
}

.badge-element{
    background-color: #FF6C00;
    color: white;
    border: 2px solid white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); 

}

.calendar-- {
    width: 600px !important;
  }




